<template>
  <div class="pay">
    <titleNav title="社保小助手" :left-arrow="true" left-text="" />
    <div class="top">
      <div>申报职业</div>
      <div>{{ goodsname }}</div>
    </div>
    <div class="tops">
      <van-icon name="records" /><span
        style="font-size: 22px; padding-top: -10px"
        >订单信息</span
      >
    </div>
    <div class="aaa">
      <div>姓名</div>
      <div>{{ name }}</div>
    </div>
    <div class="aaa">
      <div>身份证号</div>
      <div>{{ idcardno }}</div>
    </div>
    <div class="aaa">
      <div>申报职业</div>
      <div>{{ goodsname }}</div>
    </div>
    <div class="aaa">
      <div></div>
      <div>
        报名费<span style="font-size: 22px; color: red; font-weight: 700"
          >￥{{ pardes.price }}元</span
        >
      </div>
    </div>
    <div class="bot">
      <div>
        合计：<span style="font-size: 24px; color: red; font-weight: 700"
          >￥{{ pardes.price }}</span
        >
      </div>
      <div @click="pay">立即支付</div>
    </div>
  </div>
</template>

<script>
import titleNav from "@/components/conetnts/titleNav.vue";
import { Toast, Icon } from "vant";
import { orderpay, getskillgoodslevelfind } from "@/network/index.js";
export default {
  components: {
    titleNav,
    [Toast.name]: Toast,
    [Icon.name]: Icon,
  },
  data() {
    return {
      name: "",
      idcardno: "",
      goodsname: "",
      pardes:{}
    };
  },
  mounted() {
    this.orderadd();
  },
  methods: {
    // 存储的数据
    orderadd() {
      let orderadd = JSON.parse(sessionStorage.getItem("orderadd"));
      console.log(orderadd);
      if (
        orderadd !== null &&
        orderadd !== "" &&
        orderadd !== undefined &&
        orderadd !== "null"
      ) {
        this.name = orderadd.name;
        this.idcardno = orderadd.idcardno;
        this.goodsid = orderadd.goodsid;
        this.goodsname = orderadd.goodsname;
        let userid = sessionStorage.getItem("id");
        let data = {
          userid,
          goodsid: orderadd.goodsid,
          levelname: orderadd.levelname,
        };
        getskillgoodslevelfind(data).then((res) => {
          this.pardes = res.data.data;
        });
      }
    },
    pay() {
      var _this = this;
      let userid = sessionStorage.getItem("id");
      let data = {
        orderid: this.$route.query.orderid,
        userid,
      };
      orderpay(data).then((res) => {
        console.log(res);
        let data = res.data.data.jsApiParameters;
        WeixinJSBridge.invoke(
          "getBrandWCPayRequest",
          {
            appId: data.appId,
            paySign: data.paySign,
            timeStamp: data.timeStamp,
            nonceStr: data.nonceStr,
            package: data.package,
            signType: data.signType,
          },
          (res) => {
            console.log(res);
            if (res.err_msg == "get_brand_wcpay_request:ok") {
              Toast("支付成功");
              sessionStorage.setItem("orderadd", "");
              if(_this.$route.query.id==1){
                _this.$router.go(-3);
              }else{
                _this.$router.go(-2);
              }
                
            } else {
              //   that.$router.push({ path: "/myOrder" });
              Toast.fail("支付失败");
              if(_this.$route.query.id==1){
                _this.$router.go(-3);
              }else{
                _this.$router.go(-2);
              }
            }
          }
        );
      });
    },
  },
};
</script>

<style scoped lang="scss">
.pay {
  .top {
    background-color: rgb(15, 128, 254);
    width: 100vw;
    height: 150px;
    color: #ffffff;
    div:nth-child(1) {
      font-size: 22px;
      font-weight: 700;
      margin-left: 15px;
      line-height: 50px;
      padding-top: 30px;
    }
    div:nth-child(2) {
      font-size: 20px;
      margin-left: 15px;
    }
  }
  .tops {
    margin-left: 15px;
    font-size: 30px;
    line-height: 80px;
    padding-top: 10px;
    display: flex;
    align-items: center;
  }
  .aaa {
    margin: 0 25px;
    font-size: 20px;
    height: 40px;
    div:nth-child(1) {
      float: left;
    }
    div:nth-child(2) {
      float: right;
    }
  }
  .bot {
    font-size: 22px;
    position: fixed;
    bottom: 0;
    width: 100vw;
    height: 80px;
    border-top: 1px solid #eee;
    div {
      display: inline-block;
    }
    div:nth-child(1) {
      line-height: 80px;
      width: 60vw;
      padding-left: 5.5vw;
      background-color: #ffffff;
    }
    div:nth-child(2) {
      width: 34.5vw;
      line-height: 80px;
      color: #ffffff;
      text-align: center;
      background-color: rgb(15, 128, 254);
    }
  }
}
</style>
